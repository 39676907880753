<!-- This Code is Licensed by schwartz-edmisten.com --> 
function initRotatingText() {
  const rotatingWords = document.querySelectorAll('.rotating-words');
  rotatingWords.forEach(function(rotatingWord) {
    const intervalTime = rotatingWord.getAttribute('data-interval') * 1000;
    const fadeSpeed = rotatingWord.getAttribute('data-fade-speed') * 1000;
    rotatingWord.style.setProperty('--fade-duration', fadeSpeed / 1000 + 's');
    const bounceAmount = rotatingWord.getAttribute('data-bounce') || 20;
    rotatingWord.style.setProperty('--bounce-in', bounceAmount + '%');
    rotatingWord.style.setProperty('--bounce-out', parseInt(bounceAmount) + 100 + '%');
    const spans = rotatingWord.querySelectorAll('.rotating-words > span');
    spans[0].classList.add('active');
    setTimeout(() => {
      spans[0].classList.add('transition');
    }, intervalTime - fadeSpeed);
    let currentSpanIndex = 0;
    const loopStop = rotatingWord.getAttribute('data-loop-stop') === 'true';
    const totalSpans = spans.length;  
    function updateActiveSpan() {
      spans[currentSpanIndex].classList.remove('active', 'transition');
      currentSpanIndex = (currentSpanIndex + 1) % totalSpans;
      spans[currentSpanIndex].classList.add('active');
      if (fadeSpeed > 0) {
        setTimeout(() => {
          spans[currentSpanIndex].classList.add('transition');
        }, intervalTime - fadeSpeed);
      }     
      if (loopStop && currentSpanIndex === (totalSpans - 1)) {
        clearInterval(interval);
      }
    }    
    const interval = setInterval(updateActiveSpan, intervalTime);
  });
}
initRotatingText();
